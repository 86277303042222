import style from './index.module.scss'
import Link from 'next/link'

interface IButtonThirdProps {
    text: string;
    link?: string;
    showIntoView?: () => void;
}

export const ButtonThird = ({text, link, showIntoView}:IButtonThirdProps) => {
    if(link) {
        return (
            <Link href={link}>
                <a rel='preload' className={`${style.button}`}>
                    {text}
                </a>
            </Link>
        )
    } else {
        return (
            <button onClick={showIntoView} className={`${style.button}`}>
                {text}
            </button>
        )
    }
}
