export interface IMainPage {
  previewSection: IPreviewSection;
  blogSection: IBlogSection;
  expertiseSection: IExpertiseSection;
  newProjectSection: INewProjectSection;
  numbersSection: INumbersSection;
  ourWorksSection: IOurWorksSection;
  reviewsSection: IReviewsSection;
  servicesSection: IServicesSection;
  technologiesSection: ITechnologiesSection;
  developmentProcessSection: IDevelopmentProcess;
  faqSection: IFAQSection;
}

interface IPreviewSection {
  title: string;
  subtitle: string;
  cards: ICards[];
}

export interface ICards {
  title: string;
  subtitle: string;
  link: string;
  imgSrc: string;
}

interface IServicesSection {
  title: string;
  subtitle: string;
  cards: IServiceCards[];
}

export interface IServiceCards {
  title: string;
  icon: string;
  link: string;
}

interface INumbersSection {
  title: string;
  subtitle: string;
  items: INumbersItem[];
}

export interface INumbersItem {
  title: string;
  icon: string;
}

interface INewProjectSection {
  title: string;
  buttonText: string;
}

interface IExpertiseSection {
  title: string;
  subtitle: string;
  cards: IExpertiseCard[];
}

export interface IExpertiseCard {
  title: string;
  icon: string;
  url: string;
}

interface ITechnologiesSection {
  title: string;
  technologies: ITechnologies[];
}

export interface ITechnologies {
  title: string;
  items: ITechnologyItem[];
}

export interface ITechnologyItem {
  title: string;
  technologies: ITechnology[];
}

export interface ITechnology {
  iconSrc: string;
  width: string;
  height: string;
  title: string;
}

interface IDevelopmentProcess {
  title: string;
  blocks: IDevelopmentProcessItem[];
}

export interface IDevelopmentProcessItem {
  title: string;
  description: string;
  imgSrc: string;
}

interface IFAQSection {
  title: string;
  accordionItems: FAQAccordionItems[];
}

export interface FAQAccordionItems {
  question: string;
  answers: string[];
}

interface IOurWorksSection {
  title: string;
  subtitle: string;
  items: IOurWorksItems[];
}

export interface IOurWorksItems {
  title: string;
  subtitle: string;
  iconSrc: string;
  link: string;
  types: string[];
}

interface IReviewsSection {
    title: string;
    comments: Array<ICustomerReviewsSectionComment>
}

export interface ICustomerReviewsSectionComment{
  companyName: string;
  specialist: string;
  comment: string;
  specialistName: string;
}

interface IBlogSection {
  title: string;
  link: string;
}


export const MainPageState: IMainPage = {
  previewSection: {
    title: `Unique Software Solutions`,
    subtitle: `High quality web and mobile application development services.`,
    cards: [
      {
        title: `Technologies`,
        subtitle: `Check our core technology stack for building modern and scalable products.`,
        imgSrc: `/main-images/preview-section/card-1.png`,
        link: `/expertise/technology`,
      },
      {
        title: `Services`,
        subtitle: `See the project development and staffing services to cover all your business needs.`,
        imgSrc: `/main-images/preview-section/card-2.png`,
        link: `/services`,
      },
      {
        title: `Expertise`,
        subtitle: `Get an industry-focused solution for Finance, Media or any other business domain.`,
        imgSrc: `/main-images/preview-section/card-3.png`,
        link: `/expertise`,
      },
    ],
  },
  servicesSection: {
    title: `Our Services`,
    subtitle: `Agilie is an IT company that has expertise in developing software and provides a wide range of outsourcing services in the field of IT. Our mission is to help startups and companies reduce software development costs and accelerate their time to market.`,
    cards: [
      {
        icon: `/main-images/service-section/pencil.png`,
        title: `Dedicated Teams`,
        link: ``,
      },
      {
        icon: `/main-images/service-section/magnifier.png`,
        title: `Staff Augmentation`,
        link: ``,
      },
      {
        icon: `/main-images/service-section/square.png`,
        title: `Software Outsourcing`,
        link: ``,
      },
      {
        icon: `/main-images/service-section/magnifier.png`,
        title: `IT Consulting`,
        link: ``,
      },
      {
        icon: `/main-images/service-section/magnifier.png`,
        title: `Software Re-engineering and IT support`,
        link: ``,
      },
    ],
  },
  numbersSection: {
    title: `Web and Mobile app development company`,
    subtitle: `Agilie team creates upscale web and mobile products with user-friendly UI/UX. We undertake the tasks of any level of complexity and implement the boldest digital ideas. Regardless of whether it is a streaming platform  or financial system, we use the latest IT technologies.`,
    items: [
      {
        title: `Years on the market`,
        icon: `/main-images/numbers-section/years.png`,
      },
      {
        title: `Projects completed`,
        icon: `/main-images/numbers-section/projects.png`,
      },
      {
        title: `Business expertises`,
        icon: `/main-images/numbers-section/expertises.png`,
      },
    ],
  },
  newProjectSection: {
    title: `Ready to start your project?`,
    buttonText: `Start a project`,
  },
  expertiseSection: {
    title: `Expertise`,
    subtitle: `Having more than 10 years of expertise in building tailored web and mobile applications, Agilie has successfully implemented over 200 projects in various industries. Owing to our hands-on experience, we create customized software for businesses, running in a wide range of areas.`,
    cards: [
      {
        title: 'Fintech',
        url: '/expertise/fintech',
        icon: '/main-images/expertise-section/fintech.png'
      },
      {
        title: 'Mobile banking',
        url: '/expertise/fintech/mobile-banking',
        icon: '/main-images/expertise-section/mobile-banking.svg'
      },
      {
        title: 'Payment solutions',
        url: '/expertise/fintech/payment-solutions',
        icon: '/main-images/expertise-section/payment-solutions.png'
      },
      {
        title: 'Blockchain software',
        url: '/expertise/fintech/blockchain-software',
        icon: '/main-images/expertise-section/blockchain-software.png'
      },
      {
        title: 'Transportation and Logistics',
        url: '/blog/blockchain-in-logistics-how-the-blockchain-technologies-are-changing-the-transportation-industry',
        icon: '/main-images/expertise-section/logistics.png'
      },
      {
        title: 'Healthcare',
        url: '/blog/mobile-healthcare-app-development-future-of-healthcare',
        icon: '/main-images/expertise-section/healthcare.png'
      },
      {
        title: 'Media',
        url: '/blog/is-an-event-discovery-app-a-worthwhile-business-idea',
        icon: '/main-images/expertise-section/media.png'
      },
      {
        title: 'Real Estate',
        url: '/blog/proptech-and-other-digital-transformations-in-real-estate',
        icon: '/main-images/expertise-section/real-estate.png'
      },
      {
        title: 'E-commerce and Marketplace',
        url: '/blog/ultimate-guide-on-how-to-create-an-nft-marketplace',
        icon: '/main-images/expertise-section/marketplace.png'
      },
    ],
  },
  technologiesSection: {
    title: `Our Technologies`,
    technologies: [
      {
        title: `Backend`,
        items: [
          {
            title: `Core tools`,
            technologies: [
              {
                title: `NestJS`,
                iconSrc: `/main-images/technologies-section/back-end/nestjs.png`,
                width: `40`,
                height: `40`,
              },
              {
                title: `Ruby on Rails`,
                iconSrc: `/main-images/technologies-section/back-end/ruby-on-rails.png`,
                width: `70`,
                height: `25`,
              },
              {
                title: `JavaScript`,
                iconSrc: `/main-images/technologies-section/back-end/javascript.png`,
                width: `40`,
                height: `40`,
              },
              {
                title: `TypeScript`,
                iconSrc: `/main-images/technologies-section/back-end/typescript.png`,
                width: `40`,
                height: `40`,
              },
            ],
          },
          {
            title: `Services`,
            technologies: [
              {
                title: `Auth0`,
                iconSrc: `/main-images/technologies-section/back-end/auth0.png`,
                width: `36`,
                height: `40`,
              },
              {
                title: `Kafka`,
                iconSrc: `/main-images/technologies-section/back-end/kafka.png`,
                width: `24`,
                height: `40`,
              },
              {
                title: `RabbitMQ`,
                iconSrc: `/main-images/technologies-section/back-end/rabbitmq.png`,
                width: `38`,
                height: `40`,
              },
              {
                title: `Elasticsearch`,
                iconSrc: `/main-images/technologies-section/back-end/elasticsearch.png`,
                width: `38`,
                height: `40`,
              },
            ],
          },
          {
            title: `Databases`,
            technologies: [
              {
                title: `MySQL`,
                iconSrc: `/main-images/technologies-section/back-end/mysql.png`,
                width: `72`,
                height: `22`,
              },
              {
                title: `PostgreSQL`,
                iconSrc: `/main-images/technologies-section/back-end/postre-sql.png`,
                width: `40`,
                height: `40`,
              },
              {
                title: `MongoDB`,
                iconSrc: `/main-images/technologies-section/back-end/mongodb.png`,
                width: `18`,
                height: `40`,
              },
              {
                title: `Redis`,
                iconSrc: `/main-images/technologies-section/back-end/redis.png`,
                width: `40`,
                height: `34`,
              },
            ],
          },
          {
            title: `Testing`,
            technologies: [
              {
                title: `Rspec`,
                iconSrc: `/main-images/technologies-section/back-end/rspec.png`,
                width: `40`,
                height: `40`,
              },
              {
                title: `Cypress`,
                iconSrc: `/main-images/technologies-section/back-end/cypress.png`,
                width: `70`,
                height: `24`,
              },
              {
                title: `Jest`,
                iconSrc: `/main-images/technologies-section/back-end/jest.png`,
                width: `36`,
                height: `40`,
              },
            ],
          },
          {
            title: `Cloud solutions`,
            technologies: [
              {
                title: `Firebase`,
                iconSrc: `/main-images/technologies-section/back-end/firebase.png`,
                width: `30`,
                height: `40`,
              },
              {
                title: `Amazon Web Services`,
                iconSrc: `/main-images/technologies-section/back-end/amazon.png`,
                width: `64`,
                height: `40`,
              },
              {
                title: `DigitalOcean`,
                iconSrc: `/main-images/technologies-section/back-end/digitalocean.png`,
                width: `40`,
                height: `40`,
              },
            ],
          },
          {
            title: `Virtualization`,
            technologies: [
              {
                title: `Docker & Docker-compose`,
                iconSrc: `/main-images/technologies-section/back-end/docker.png`,
                width: `48`,
                height: `34`,
              },
            ],
          },
        ],
      },
      {
        title: `Frontend`,
        items: [
          {
            title: `Languages`,
            technologies: [
              {
                title: `CSS`,
                iconSrc: `/main-images/technologies-section/front-end/css.png`,
                width: `28`,
                height: `40`,
              },
              {
                title: `HTML`,
                iconSrc: `/main-images/technologies-section/front-end/html.png`,
                width: `28`,
                height: `40`,
              },
              {
                title: `JavaScript`,
                iconSrc: `/main-images/technologies-section/front-end/javascript.png`,
                width: `40`,
                height: `40`,
              },
              {
                title: `TypeScript`,
                iconSrc: `/main-images/technologies-section/front-end/typescript.png`,
                width: `40`,
                height: `40`,
              },
            ],
          },
          {
            title: `Frameworks`,
            technologies: [
              {
                title: `Angular`,
                iconSrc: `/main-images/technologies-section/front-end/angular.png`,
                width: `38`,
                height: `40`,
              },
              {
                title: `ReactJS`,
                iconSrc: `/main-images/technologies-section/front-end/reactjs.png`,
                width: `38`,
                height: `40`,
              },
              {
                title: `VueJS`,
                iconSrc: `/main-images/technologies-section/front-end/vuejs.png`,
                width: `40`,
                height: `34`,
              },
            ],
          },
          {
            title: `Cross-platform solutions`,
            technologies: [
              {
                title: `Ionic`,
                iconSrc: `/main-images/technologies-section/front-end/ionic.png`,
                width: `40`,
                height: `40`,
              },
              {
                title: `NativeScript`,
                iconSrc: `/main-images/technologies-section/front-end/nativescript.png`,
                width: `40`,
                height: `40`,
              },
              {
                title: `React Native`,
                iconSrc: `/main-images/technologies-section/front-end/reactnative.png`,
                width: `38`,
                height: `40`,
              },
            ],
          },
          {
            title: `CSS libraries and frameworks`,
            technologies: [
              {
                title: `Bootstrap`,
                iconSrc: `/main-images/technologies-section/front-end/bootstrap.png`,
                width: `40`,
                height: `32`,
              },
              {
                title: `Materialize`,
                iconSrc: `/main-images/technologies-section/front-end/materialize.png`,
                width: `62`,
                height: `32`,
              },
              {
                title: `Kendo UI`,
                iconSrc: `/main-images/technologies-section/front-end/kendo.png`,
                width: `30`,
                height: `40`,
              },
              {
                title: `Prime`,
                iconSrc: `/main-images/technologies-section/front-end/prime.png`,
                width: `38`,
                height: `40`,
              },
            ],
          },
          {
            title: `Testing`,
            technologies: [
              {
                title: `Jasmine`,
                iconSrc: `/main-images/technologies-section/front-end/jasmine.png`,
                width: `40`,
                height: `40`,
              },
              {
                title: `Karma`,
                iconSrc: `/main-images/technologies-section/front-end/karma.png`,
                width: `40`,
                height: `32`,
              },
              {
                title: `Cypress`,
                iconSrc: `/main-images/technologies-section/front-end/cypress.png`,
                width: `70`,
                height: `24`,
              },
              {
                title: `Jest`,
                iconSrc: `/main-images/technologies-section/front-end/jest.png`,
                width: `36`,
                height: `40`,
              },
            ],
          },
        ],
      },
      {
        title: `iOS`,
        items: [
          {
            title: `General`,
            technologies: [
              {
                title: `Objective-C`,
                iconSrc: `/main-images/technologies-section/ios/objective-c.png`,
                width: `28`,
                height: `40`,
              },
              {
                title: `Swift`,
                iconSrc: `/main-images/technologies-section/ios/swift.png`,
                width: `40`,
                height: `40`,
              },
              {
                title: `SwiftUI`,
                iconSrc: `/main-images/technologies-section/ios/swiftui.png`,
                width: `40`,
                height: `40`,
              },
              {
                title: `CloudKit`,
                iconSrc: `/main-images/technologies-section/ios/cloudkit.png`,
                width: `40`,
                height: `40`,
              },
            ],
          },
          {
            title: `Integrations`,
            technologies: [
              {
                title: `Apple Maps`,
                iconSrc: `/main-images/technologies-section/ios/apple-maps.png`,
                width: `40`,
                height: `40`,
              },
              {
                title: `Google Maps`,
                iconSrc: `/main-images/technologies-section/ios/google-maps.png`,
                width: `28`,
                height: `40`,
              },
              {
                title: `WebRTC`,
                iconSrc: `/main-images/technologies-section/ios/webrtc.png`,
                width: `40`,
                height: `40`,
              },
              {
                title: `SpriteKit`,
                iconSrc: `/main-images/technologies-section/ios/spritekit.png`,
                width: `40`,
                height: `40`,
              },
            ],
          },
          {
            title: `Work&Test`,
            technologies: [
              {
                title: `CocoaPods`,
                iconSrc: `/main-images/technologies-section/ios/cocoapods.png`,
                width: `40`,
                height: `40`,
              },
              {
                title: `Xcode`,
                iconSrc: `/main-images/technologies-section/ios/xcode.png`,
                width: `40`,
                height: `40`,
              },
              {
                title: `TestFlight`,
                iconSrc: `/main-images/technologies-section/ios/testflight.png`,
                width: `40`,
                height: `40`,
              },
              {
                title: `Firebase`,
                iconSrc: `/main-images/technologies-section/ios/firebase.png`,
                width: `30`,
                height: `40`,
              },
            ],
          },
        ],
      },
      {
        title: `Android`,
        items: [
          {
            title: `Languages`,
            technologies: [

              {
                title: `Java`,
                iconSrc: `/main-images/technologies-section/android/java.png`,
                width: `22`,
                height: `40`,
              },
              {
                title: `Kotlin`,
                iconSrc: `/main-images/technologies-section/android/kotlin.png`,
                width: `40`,
                height: `40`,
              },
            ],
          },
          {
            title: `Integrations`,
            technologies: [
              {
                title: `WebRTC`,
                iconSrc: `/main-images/technologies-section/android/webrtc.png`,
                width: `40`,
                height: `40`,
              },
              {
                title: `Android Wear`,
                iconSrc: `/main-images/technologies-section/android/androidwear.png`,
                width: `64`,
                height: `32`,
              },
              {
                title: `Google Maps APIs`,
                iconSrc: `/main-images/technologies-section/android/google-maps.png`,
                width: `28`,
                height: `40`,
              },
            ],
          },
          {
            title: `General`,
            technologies: [
              {
                title: `Android Studio`,
                iconSrc: `/main-images/technologies-section/android/android-studio.png`,
                width: `38`,
                height: `40`,
              },
              {
                title: `Android SDK`,
                iconSrc: `/main-images/technologies-section/android/android-sdk.png`,
                width: `40`,
                height: `40`,
              },
              {
                title: `Firebase`,
                iconSrc: `/main-images/technologies-section/android/firebase.png`,
                width: `30`,
                height: `40`,
              },
              {
                title: `Flutter`,
                iconSrc: `/main-images/technologies-section/android/flutter.png`,
                width: `32`,
                height: `40`,
              },
            ],
          },
        ],
      },
    ],
  },
  ourWorksSection: {
    title: `Our Works`,
    subtitle: `The Agilie team has successful experience in the development and implementation of mobile apps, and web projects of various levels of complexity for commercial structures.`,
    items: [
      {
        title: `Bookis`,
        subtitle: `The client had an idea of a mobile marketplace allowing users to distribute books among themselves by exchanging literature pieces for their cash equivalent. We helped him to build an app working on IOS and Android platforms. Thanks to it, people can buy and sell books in supreme comfort.`,
        link: `/success-stories/bookis`,
        iconSrc: `/main-images/our-works-section/bookis.png`,
        types: [`iOS`, `Android`],
      },
      {
        title: `Givingli`,
        subtitle: `The client wanted to create a simple service for sending electronic greeting cards and invitations to the holidays. We developed the Givingli project from scratch, including UI/UI design, software architecture development with the support of cloud scaling due to numerous active online users.`,
        link: `/success-stories/givingli`,
        iconSrc: `/main-images/our-works-section/givingli.png`,
        types: [`iOS`, `Android`],
      },
      {
        title: `Chameleon`,
        subtitle: `The client had an idea to create an e-wallet app, and he resorted to our help. We started the project from scratch and developed Chameleon Pay: a multi-digital wallet helping users store, receive, send, convert, and perform other similar cryptocurrency manipulations.`,
        link: `/success-stories/chameleon-pay`,
        iconSrc: `/main-images/our-works-section/chameleon.png`,
        types: [`NativeScript`, `iOS`, `Android`],
      },
    ],
  },
  reviewsSection: {
    title: 'Customer Reviews',
        comments: [
            {
                companyName: `Knear`,
                specialist: `CEO`,
                comment: `Agilie delivered an app that met discerning standards and offers a range of functionalities. The developers excelled at making creative suggestions and solving complex problems.`,
                specialistName: `Alexandru Muresan`,
            },
            {
                companyName: `Chainzilla`,
                specialist: `CEO`,
                comment: `We contacted Agilie when we already had an application, but its quality and functionality did not satisfy us. After the whole work, we can say Agilie team was the right decision for this app. Users are extremely happy with clean design, colours and simple functionality.`,
                specialistName: `Charles Gonzales`,
            },
            {
                companyName: `Original YEAH`,
                specialist: `Marketing director`,
                comment: `Agilie has done a great job these months. We provided them with our idea and design, and their goal was to make a custom online store within a specified period. We are completely satisfied.`,
                specialistName: `Pedro Racionero`,
            },
            {
                companyName: `EasyWay`,
                specialist: `CEO`,
                comment: `Our company contacted the Agilie team and asked to develop mobile applications for our service Easy Way - public routes in Ukraine. The results exceeded our expectations. The number of services used increased by 40% the first month the application has been published. As a result, Agilie became our business partner for our mobile software.`,
                specialistName: `Ivan Sherstyuk`,
            },
            {
                companyName: `Online Education Platform`,
                specialist: `CEO`,
                comment: `Agilie was great at taking direction and the feedback on user testing has been amazing so far. They’re a cost-effective and responsive team that communicates daily and delivers on time. They have a friendly disposition and are passionate about the work.`,
                specialistName: ``,
            },
            {
                companyName: `Cast Group`,
                specialist: `CEO`,
                comment: `The professional approach I have seen from the Agilie team dispelled my concerns about the result I would get. I was pleased to work with them and would recommend this company to others.`,
                specialistName: `Henrik Lilja`,
            },
            {
                companyName: `Wirex Limited`,
                specialist: `Director`,
                comment: `We highly recommend Agilie based on our one-year cooperation experience. Their team is always very motivated, passionate about innovation and tremendously skillful. They helped us to move our products and services to another level. It has been a great pleasure to work with Eugene, Alex, Alina and the rest of the team.`,
                specialistName: `Dmitry Lazarichev`,
            },
        ]
  },
  blogSection: {
    title: `Blog`,
    link: `/blog`
  },
  developmentProcessSection: {
    title: 'Our development process',
    blocks: [
      {
        title: 'Research or discovery phase',
        description: 'During the discovery phase, we conduct extensive research and planning to help us better understand the needs and requirements of the project. This might involve conducting market research, surveying potential users, or analyzing existing software solutions on the internet in the same niche.',
        imgSrc: '/main-images/development-process-section/search.png'
      },
      {
        title: 'Documentation',
        description: 'Once the project plan is in place, we begin the documentation stage. We document every aspect of the project, from the technical requirements to the user interface design. By documenting everything up front, we can ensure that everyone on the team has a clear understanding of the project and its goals.',
        imgSrc: '/main-images/development-process-section/documentation.png'
      },
      {
        title: 'Prototyping',
        description: 'Then we move on to prototyping. Prototyping involves creating early-stage versions of the software to help us test out various ideas and concepts. These prototypes are often rough around the edges and may not have all the features and functionality of the final product, but they provide a valuable way to get early feedback and ensure that we\'re on the right track.',
        imgSrc: '/main-images/development-process-section/prototyping.png'
      },
      {
        title: 'Design',
        description: 'With the prototyping stage complete, we go to the design. This stage is about creating the final user interface and visual web design for the software. Our team of designers works closely with the development team to ensure that the design is both aesthetically pleasing and functional, and that it meets the needs of the end-users and provides an outstanding user experience.',
        imgSrc: '/main-images/development-process-section/design.png'
      },
      {
        title: 'Development',
        description: 'Next is the web development stage. This is where the actual coding and programming take place, and it\'s often the longest and most complex stage of the development process. Our team of developers works closely together to ensure that the code is clean, efficient, and well-documented, and that the software is built to the highest quality standards.',
        imgSrc: '/main-images/development-process-section/development.png'
      },
      {
        title: 'Quality Assurance',
        description: 'Once the development is complete, the quality assurance (QA) stage starts. During this stage, we conduct extensive testing to ensure that the software is free of bugs and other issues. We use a variety of testing methods to make sure that the software is stable and performs as expected.',
        imgSrc: '/main-images/development-process-section/qa.png'
      },
      {
        title: 'Release support',
        description: 'Finally, once the software has passed through the QA stage, we move on to release support. This involves preparing the software for release and providing ongoing support and maintenance for the software once it\'s out in the wild. We work closely with our clients to ensure that the software is launched smoothly and that any issues or bugs that arise after launch are promptly addressed.',
        imgSrc: '/main-images/development-process-section/support.png'
      }
    ]
  },
  faqSection: {
    title: 'FAQ',
    accordionItems: [
      {
        question: 'What are the services offered by a mobile and web app development company?',
        answers: [
            'Since 2010, we have been helping our clients keep up with the times by transforming their business ideas into high-tech solutions. Our specialists develop reliable web and mobile applications of any level of complexity that meet all the requirements of our customers and contribute to the achievement of established business goals.',
            'At the moment, there are many different types of software used to solve specific business problems. Agilie offers Dedicated teams, Staff Augmentation, Software Outsourcing, IT consulting, Software re-engineering, and IT support.',
        ]
      },
      {
        question: 'What technologies are used for mobile and web app development?',
        answers: [
            'A range of programming languages and tools are utilized in web development technologies to design dynamic websites as well as apps.',
            'Front-end technologies are utilized to create engaging user experiences on the client side of websites, and your site\'s interactive elements, such as buttons or menus, are created by using them. The design integrates various elements like text coloring/styling, button placement, image usage, and navigational tools.',
            'Backend technologies store and organize data and make sure that everything works on the interface. For example, when a user provides login credentials to a social networking application, internal technologies are used to validate those credentials. After verifying the credentials, the server will send back the profile name, image, and other related information.',
            'Agilie specializes in the design and development of applications for Apple devices, catering to iOS apps for iPhone, iPad, and even companion Apple Watch apps. Our team ensures the utmost quality of our code, utilizing continuous integration (CI) practices through tools like Jenkins and Xcode Server. When it comes to Android mobile applications, we employ Java and Kotlin to create robust apps, harnessing the full potential of Android Studio. Additionally, we integrate custom libraries to deliver functionalities that cater to diverse audiences and enhance user experiences'
        ]
      },
      {
        question: 'What are the differences between mobile and web apps?',
        answers: [
            'Web applications are accessed through web browsers and run within the browser environment. Mobile apps are specifically designed and developed for mobile devices, installed directly on the device, and accessed through app stores. The difference between them is easiest to see when using, for example, the mobile banking application and its web version.',
        ]
      },
      {
        question: 'What are the costs associated with mobile and web app development?',
        answers: [
            'There are various factors that affect the application development cost:',
            '<ul><li>Number of functions and application screens</li><li>Application complexity</li><li>Third-Party Integrations</li><li>Implementation of modern security and privacy protocols</li><li>Size, level, and experience of the application development team</li><li>Number of application developers working on one project</li><li>Target Platform</li></ul>',
            'At Agilie, we provide cost-effective solutions while keeping up with your project\'s requirements. Our skilled development team works efficiently to reduce development time and expenses while maintaining product quality. We guarantee precise cost estimates by conducting extensive briefings, and completing transparent project validation processes during the Discovery phase.'
        ]
      },
      {
        question: 'Is Web development the same as mobile app development?',
        answers: [
            'Web development and mobile app development are related but distinct fields. Web development focuses on creating websites and web applications that run in web browsers on various devices, utilizing technologies like HTML, CSS, and JavaScript. In contrast, mobile app development involves building applications specifically for mobile devices, utilizing platform-specific languages and frameworks that interact directly with the device\'s operating system. Web development emphasizes cross-device compatibility and adaptability, while mobile app development prioritizes optimized interfaces, touch-based interactions, and native device features. Additionally, web apps are accessed through URLs without installation, while mobile apps require distribution through app stores and installation on users\' devices.'
        ]
      }
    ]
  }
};
