import dynamic from 'next/dynamic';
import style from '@styles/reusable.styles.module.scss';
import { IArticle } from '@models/article.interface';
import { MainPageState } from './main-page.state';
import { scrollToBlock } from '@services/scrollToBlock';
import { StartProjectBlock } from '@components/shared/blocks/start-project-block';
import { StartProjectComponent } from '@components/shared/interactions/start-project-component';

const PreviewSection = dynamic(() => import('./sections/preview-section'), { ssr: true, });
const ServicesSection = dynamic(() => import('./sections/services-section'), { ssr: true, });
const NumbersSection = dynamic(() => import('./sections/numbers-section'), { ssr: true, });
const ExpertiseSection = dynamic(() => import('./sections/expertise-section'), { ssr: true, });
const TechnologiesSection = dynamic(() => import('./sections/technologies-section'), { ssr: true, });
const DevelopmentProcessSection = dynamic(() => import('./sections/development-process-section'), { ssr: true, });
const OurWorksSection = dynamic(() => import('./sections/our-works-section'), { ssr: true, });
const BlogSection = dynamic(() => import('./sections/blog-section'), { ssr: true, });
const ReviewsSectionComponent = dynamic(() => import('./sections/reviews-section/reviews-section'), { ssr: true, });
const FAQSectionComponent = dynamic(() => import('./sections/faq-section'), { ssr: true, });

interface MainPageProps {
    articles: IArticle[];
}

const MainPage = (props: MainPageProps) => {
    const { articles } = props;
    const {
        previewSection,
        servicesSection,
        numbersSection,
        newProjectSection,
        expertiseSection,
        technologiesSection,
        developmentProcessSection,
        ourWorksSection,
        reviewsSection,
        blogSection,
        faqSection
    } = MainPageState;
    return (
        <>
            <PreviewSection
                title={previewSection.title}
                subtitle={previewSection.subtitle}
                cards={previewSection.cards}
                scrollIntoView={() => scrollToBlock('mainPageForm')}
            />
            <NumbersSection
                title={numbersSection.title}
                subtitle={numbersSection.subtitle}
                items={numbersSection.items}
            />
            <ServicesSection
                title={servicesSection.title}
                subtitle={servicesSection.subtitle}
                cards={servicesSection.cards}
            />
            <StartProjectBlock
                title={newProjectSection.title}
                buttonText={newProjectSection.buttonText}
                showIntoView={() => scrollToBlock('mainPageForm')}
            />
            <ExpertiseSection
                title={expertiseSection.title}
                subtitle={expertiseSection.subtitle}
                cards={expertiseSection.cards}
            />
            <TechnologiesSection
                title={technologiesSection.title}
                technologies={technologiesSection.technologies}
            />
            <DevelopmentProcessSection
                title={developmentProcessSection.title}
                items={developmentProcessSection.blocks}
            />
            <OurWorksSection
                title={ourWorksSection.title}
                subtitle={ourWorksSection.subtitle}
                items={ourWorksSection.items}
            />
            <ReviewsSectionComponent
                title={reviewsSection.title}
                comments={reviewsSection.comments}
            />
            <BlogSection
                title={blogSection.title}
                link={blogSection.link}
                articles={articles}
            />
            <FAQSectionComponent
                title={faqSection.title}
                hoverColor={'white'}
                accordionItems={faqSection.accordionItems}
            />
            <section className={`${style.section} ${style.section__contact}`}>
                <div id="mainPageForm" className={`${style.container} ${style.container_small}`}>
                    <StartProjectComponent title={"Start a project"}/>
                </div>
            </section>
        </>
    );
};

export default MainPage;
