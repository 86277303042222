import type { GetServerSideProps, GetServerSidePropsContext, InferGetServerSidePropsType, NextPage } from 'next'
import MainLayout from '@layouts/main-layout/main-layout';
import MainPage from '@components/main-page/main-page';
import {getPopularArticles} from '@services/blog.service';
import Head from 'next/head';
import { MetaTags } from '@helpers/meta-tags.helper';

const Home: NextPage = ({ articles }: InferGetServerSidePropsType<typeof getServerSideProps>) => {
    return (
        <>
            <Head>
                {
                    MetaTags({
                        title: 'Agilie: Web and Mobile App Development Company',
                        description: 'Agilie is a top-rated web and mobile app development company. We offer high-quality services for businesses of all sizes. Contact us today to get started! ',
                        imageUrl: '/main-images/main-meta-image.png',
                    })
                }
                <link rel="icon" href="/favicon.ico" />
            </Head>
            <MainLayout>
                <MainPage articles={articles} />
            </MainLayout>
        </>
    )
}

export const getServerSideProps: GetServerSideProps = async () => {
    const articles = await getPopularArticles(6);
    return {
        props: {
            articles: articles.data,
        }
    }
}

export default Home;
