import { ButtonThird } from '@components/shared/buttons/button-third';
import style from './index.module.scss'

interface IStartProjectBlockProps {
    title: string;
    buttonText: string;
    link?: string;
    showIntoView?: () => void;
}

export const StartProjectBlock = ({ title, buttonText, link, showIntoView }: IStartProjectBlockProps) => {
    return (
        <section className={`${style.section}`}>
            <div className={`${style.block}`}>
                <div className={`${style.title}`}>
                    {title}
                </div>
                <div className={`${style.button}`}>
                    <ButtonThird text={buttonText} link={link} showIntoView={showIntoView}/>
                </div>
            </div>
        </section>
    )
}